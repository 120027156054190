<template>
    <div class="pankouBox" :class="{pankouBox_phone:isMobile==1}">
        <div class="openKlineOrOpensea" v-if="isMobile==1" @click="toggleKlineOrOpensea">
            <div>
				<img src="@/assets/images/kline.png" alt="">
				<template v-if="!showKlineOrOpensea">
					{{web != 'opensea'?$t('stocks.openKline'):'NFT'}}
				</template>
				<template v-else>
					{{web != 'opensea'?$t('stocks.closeKline'):'NFT'}}
				</template>
            </div>
        </div>

        <div class="head" v-if="isMobile==0">
            <div class="left"><i class="iconfont icon-fullscreen"></i>{{ $t('stocks.common') }}</div>
            <div class="right disabled" @click="handleFull" v-if="web_type == 0 || web_type == 2">
                {{ $t('stocks.advanced') }}
                <i class="iconfont2 icon-quanping"></i>
            </div>
        </div>
        <div class="headTitle" v-else>{{ $t('stocks.common') }}</div>

        <div class="list">
            <div class="hd">
                <div class="price">{{ $t('common.pirce') }}</div>
                <div class="number">{{ $t('common.num') }}</div>
            </div>
            <div class="bd">
                <div class="left">
                    <div @click="selectData(item, 'asksList', index)" class="item" v-for="(item, index) in asksList"
                        :key="index">
                        <div class="price">
                            <span class="cricle" :class="{
                                hasOrder: orderList && orderList.length > 0 ? orderList.findIndex(target => target.price == item.price) >= 0 : false
                            }"></span>
                            {{ item.price }}
                        </div>
                        <div class="number">{{ item.amount }}</div>
                    </div>
                    <div id="sell_end"></div>
                </div>
                <div class="line"></div>
                <div class="right">
                    <div @click="selectData(item, 'bidsList', index)" class="item" v-for="(item, index) in bidsList"
                        :key="index">
                        <div class="price">
                            <span class="cricle" :class="{
                                hasOrder: orderList && orderList.length > 0 ? orderList.findIndex(target => target.price == item.price) >= 0 : false
                            }"></span>
                            {{ item.price }}
                        </div>
                        <div class="number">{{ item.amount }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 移动端判定-高级盘口按钮 -->
        <div class="headTitle advanced" @click="handleFull" v-if="isMobile==1&&web_type==0">
            <span>{{$t('stocks.advanced')}}</span>
            <i class="iconfont2 icon-right_arrow"></i>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import numFormat from '@/utils/methApi/numFormat';
    export default {
        props: ['web', 'web_type', 'bidsList', 'asksList', 'orderList'],
        computed: {
            ...mapGetters(['isMobile','showKlineOrOpensea']),
        },
        methods: {
            // 打开K线、NFT漂浮窗
            toggleKlineOrOpensea() {
                this.$store.commit('markets/SET_SHOW_KLINE_OR_OPENSEA', !this.showKlineOrOpensea)
            },
            // 选中普通盘口 快捷填入数据-手动下单-cex
            selectData(item, type, index) {
                if (this.web_type == 0) {
                    this.$emit('selectData', item.price, type, index)
                }
            },
            // 数字格式化-普通盘口-数量保留小数加单位
            numFormat(num, type) {
                return numFormat(num, type)
            },
            // 跳转高级盘口
            handleFull() {
                if (this.web_type == 0) {
                    this.$router.push({
                        path: '/stocksFull'
                    })
                } else {
                    // opensea 敬请期待
                    this.$message({
                        message: this.$t('tip.waiting'),
                        center: true,
                        customClass: 'shotMsg',
                    });
                }
            },
            sellScrollToBottom() {
                var sell_end = document.getElementById('sell_end');
                sell_end?.scrollIntoView(false)
            },
        },
    }
</script>
<style scoped="scoped" lang="stylus">
    @import './pankouBox.styl';
</style>