// 数字格式化-盘口数量保留小数加单位
const numFormat=function(num,type) {
    if(!!num){
        if(num>Math.pow(10,9)){
            return Math.floor(num/10000000) / 100+'B'
        }else if(num>Math.pow(10,6)){
            return Math.floor(num/10000) / 100+'M'
        }else if(num>Math.pow(10,3)){
            return Math.floor(num/10) / 100+'K'
        }else if(num<Math.pow(10,-6)){
            return Number(num).toPrecision(3)
        }else{
            // 0.000001~1000
            // if(type=='vol'){
                // 数量 保留4位小数
                return Math.floor(num*10000) / 10000
            // }else{
            //     // 价格 保留6位小数
            //     return Math.floor(num*1000000) / 1000000
            // }
        }
    }else{
        return num
    }
}
export default numFormat